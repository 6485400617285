/* eslint-disable react/no-danger */
import React, { FC, ComponentProps } from 'react';
import { Dayjs } from 'dayjs';

import faceIcon5 from '@/newcar/ui/top/img/uservoice/face-icon-5.svg';
import starOn from '@/newcar/ui/top/img/uservoice/star_on.svg';
import starOff from '@/newcar/ui/top/img/uservoice/starOff.svg';
import * as styles from './UserVoiceCard.module.styl';

interface Props {
  initial: string
  prefecture: string
  age: string
  maker: string
  carModel: string
  ratingOverall: number
  ratingWebsite?: number
  ratingCS: number
  ratingCar: number
  comment: string
  postedAt: Dayjs
  carImage?: string
}

const UserVoiceCard: FC<Props & ComponentProps<'div'>> = ({
  className,
  initial,
  prefecture,
  age,
  maker,
  carModel,
  ratingOverall,
  ratingWebsite,
  ratingCS,
  ratingCar,
  comment,
  postedAt,
  carImage,
}) => (
  <div className={`box is-padding-4 ${styles.userVoiceCard} ${className}`}>
    <div className="columns is-mobile">
      <div className={`column is-narrow is-margin-right-3 is-flex ${styles.imgContainer}`}>
        <img
          src={carImage ?? faceIcon5}
          alt="満足度5"
          width="34"
          height="34"
        />
      </div>
      <div className={`column user-detail ${styles.userDetail}`}>
        <p className="column">{`${initial}さん(${prefecture}/${age})`}</p>
        <p className="column">{`ご契約のお車：${maker} ${carModel}`}</p>
      </div>
    </div>
    <div className="columns is-mobile is-vcentered is-margin-bottom-1 is-margin-top-5">
      {/* 総合 */}
      <div className="column is-narrow rating-overall-text">
        <p className="is-size-7-tablet is-size-6-mobile is-margin-right-2 has-text-weight-semibold">
          総合
        </p>
      </div>
      {/* 評価点 */}
      <div className="column is-narrow rating-overall-number">
        <p className="is-size-6-tablet is-size-5-mobile is-margin-right-2 has-text-primary">
          {ratingOverall.toFixed(1)}
        </p>
      </div>
      {/* 評価星 */}
      <div className="column is-flex is-narrow is-margin-right-2">
        {Array(5)
          .fill(starOff)
          .map((star, i) => (i + 1 > ratingOverall ? star : starOn))
          .map((src, i) => (
            <img
              className={styles.star}
              src={src}
              alt=""
              key={`star-${i + 1}`}
              width="38"
              height="36"
            />
          ))}
      </div>
    </div>
    <p className={`${styles.ratings} ratings`}>
      {ratingWebsite ? (
        <>
          Webサイト:
          <span className="has-text-primary">
            {ratingWebsite}
          &nbsp;
          </span>
          <span className="has-text-grey">|</span>
        </>
      ) : null}
      接客サービス:
      <span className="has-text-primary">
        {ratingCS}
        &nbsp;
      </span>
      <span className="has-text-grey">|</span>
      リースした車:
      <span className="has-text-primary">
        {ratingCar}
      </span>
    </p>
    <p
      className={`is-margin-top-7 comment ${styles.comment}`}
      dangerouslySetInnerHTML={{ __html: comment }}
    />
    <p className={`has-text-right has-text-grey ${styles.postedAt}`}>
      投稿日：
      {postedAt.format('YYYY/MM/DD')}
    </p>
  </div>
);

export default UserVoiceCard;
